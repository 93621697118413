<template>
    <div>
        <b-modal ref="modalPrint" @show="init_component">
            <template slot="modal-header" slot-scope="{ close }">
                <i class="btn btn-link my-auto" @click="close()"><font-awesome-icon :icon="['fal', 'long-arrow-left']" /></i>
                <h2 class="mx-auto my-auto">{{ $t('monte.imprimer_planning_period') }}</h2>
            </template>

            <template slot="default">

                <div class="alert alert-warning  d-flex align-items-center" role="alert">
					<font-awesome-icon :icon="['fal', 'exclamation-triangle']"/>
                    <div class="ml-3">{{ $t('monte.imprimer_planning_period_warning') }}</div>
                </div>

                <div class="row">
                    <div class="form-group col-6">
                        <label for="date_from">{{ $t('monte.print_from') }}</label>
                        <e-datepicker id="date_from" v-model="date_from"></e-datepicker>
                    </div>
                    <div class="form-group col-6">
                        <label for="date_to">{{ $t('monte.print_to') }}</label>
                        <e-datepicker id="date_to" v-model="date_to"></e-datepicker>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <b-form-checkbox
                            id="only_active"
                            v-model="only_active"
                        >
                        {{ $t('monte.show_only_active_stallion') }}
                        </b-form-checkbox>
                    </div>
                </div>

        

                <EEasyPrint ref="print" table-show class="d-none">
                    <component
                        v-if="to_print"
                        :is="componentName"
                        :params="params"
                        :only_active="only_active"
                        :readyToPrint="readyToPrint"
                    />
                </EEasyPrint>
            </template>

            <template slot="modal-footer" class="justify-content-center">
                <b-button variant="primary" @click="print">
                    <font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse  class="mr-1"/>
                    <font-awesome-icon v-else :icon="['fal', 'print']" class="mr-1"/>{{ $t('monte.imprimer') }}
                </b-button>
                <b-button @click="closeModal()">
                    <font-awesome-icon :icon="['fal', 'times']" class="mr-1"/> {{ $t('global.annuler') }}
                </b-button>
            </template>
        </b-modal>
    </div>
</template>


<script type="text/javascript">
	export default {
        props:['date', 'componentName', 'lieu_id'],
        data () {
            return {
                processing: false,
                week: true,
                date_from: new Date(),
                date_to: new Date(),
                print_date: new Date(),
                to_print: false,
                params: {},
                only_active: false
            }
        },
        methods: {
            init_component() {
                this.processing = false
                this.week = true
                this.date_from = this.date
                this.date_to = this.date
                this.print_date = this.date
                this.to_print = false
                this.params = {}
            },

            openModal() {
                this.$refs.modalPrint.show()
            },

            closeModal() {
                this.$refs.modalPrint.hide()
            },

            calculDifference(date1, date2) {
                date1.setHours(0,0,0,0);
                date2.setHours(0,0,0,0);
                let Difference_In_Time = date2.getTime() - date1.getTime(); 
                let Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24); 
                return Math.round(Difference_In_Days);
            },

            print () {
                this.processing = true
                this.params = {date_from: this.print_date, lieu_id: this.lieu_id}
                if(this.week) {
                    this.params = {
                        date_from: this.date_from,
                        date_to: this.date_to, 
                        lieu_id: this.lieu_id
                    }
                }

                this.to_print = true 
            },

            readyToPrint() {
                this.$nextTick(async () => {
                    await this.$refs.print.print()
                    this.to_print = false
                    this.processing = false
                })
            }
        },
        watch: {
            date_from(val) {
                const diff = this.calculDifference(val, this.date_to)
                if(diff > 7) {
                    this.date_to = new Date(val.valueOf());
                    this.date_to.setDate(this.date_from.getDate() + 7);
                }
            },

            date_to(val) {
                const diff = this.calculDifference(this.date_from, val)
                if(diff > 7) {
                    this.date_from = new Date(val.valueOf());
                    this.date_from.setDate(this.date_from.getDate() - 7);
                }
            }
        },
        components: {
            PrintCalendarMain : () => import('@/components/Monte/PrintCalendarMain'),
            PrintCalendarIA : () => import('@/components/Monte/PrintCalendarIA'),
            EEasyPrint : () => import('GroomyRoot/components/Utils/EEasyPrint'),
        }
	}
</script>
